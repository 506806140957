@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600;700;800&display=swap);
body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: #000;
  /* background-image: url('/src/assets/hero-animation/shapesv2.png'); */
}

html{
  scroll-behavior: smooth;
}

.contact-address{
  font-size: 14px !important;
  font-weight: bold;
}

.deverd-div{
  padding-bottom: 50px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.copybutton{
  text-align: left;
    padding-bottom: 20px;
}

.copybutton span{
  color: #fff !important;
  margin-left: 5px;
}

.copybutton button{
  font-weight: bold;
}

.starparalax.commingsoon{
  min-height: 100vh;
}

.google-translate-div{
  text-align: center;
  padding: 5px;
}

.hero-header-text img{
  width: 315px;
}

.social-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.lottieAvatar iframe{
  margin-left: -50%;
}

.lottieAvatar div{
  max-height: 310px !important;
}

.main-timer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  padding-top: 40px;
}

.lottie-main{
  max-width: 800px;
  margin: 0 auto;
}

.lottie-sub{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100vh;
}

.time {
  font-size: 32px;
}

.starparalax.commingsoon .main-logo-cont{
  border-bottom: none;
}

.starparalax.commingsoon .ring-container, .starparalax.commingsoon .button-container {
  display: table;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}

.starparalax.commingsoon .button-container{
  padding: 0;
}

.social-links{
  /* background-color: #242424; */
  text-align: center;
  padding: 5px 20px 5px 5px;
  /* display: flex; */
  position: fixed;
  z-index: 1000000;
  right: 0;
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.479);
}

.social-main-content{
  background-color: #000;
  border-bottom: 1px solid #3d3d3d;
}

.social-icon{
  color: #fe00ff;
  width: 33px;
  height: 33px;
  border-radius: 4px;
  background: #d8d8d83b;
  display:table;
  margin-bottom: 5px;
}

.social-cell{
  display: table-cell;
  vertical-align: middle;
}

.social-icon:hover, .social-icon:focus{
  color: #c700c7;
  background: #d8d8d824;
  outline: none !important;
}

.main-nav-bar{
  background-color: #000;
  padding: 5px;
}

.nav-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.logo-container img{
  height: 50px;
  width: auto;
}

.logo-container{
  padding: 5px 0;
}

.nav-bar-items ul{
  padding: 0;
  margin: 0;
}

.nftimage-container{
  padding: 30px 0;
}

.market-box{
  width: 23%;
  display: inline-block;
  vertical-align: top;
  margin: 1%;
}

.market-main-row{
  text-align: center;
}

.market-container{
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact-items button{
  background-color: #bb00b2;
    border-color: #bb00b2;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
}

.market-box-sub{
  padding: 20px 15px;
  border-radius: 10px;
}

.atomic{
  background-color: #D3E4CD;
}

.blockparty{
  background-color: #F6EABE;
}

.cargonfts{
  background-color: #94DAFF;
}

.enjinlogo{
  background-color: #FFC4E1;
}
.knownorigin{
  background-color: #94B3FD;
}
.makersplace{
  background-color: #E7EAB5;
}
.mintbase{
  background-color: #F0D9FF;
}
.niftygateway{
  background-color: #F6AE99;
}
.opensea{
  background-color: #F4D19B;
}
.rarible{
  background-color: #94B3FD;
}
.simplemarket{
  background-color: #96C7C1;
}
.superrare{
  background-color: #C1CFC0;
}

.market-box-sub h3{
  font-weight: bold;
}

.market-box-sub h4{
  font-size: 14px;
}

.market-box-sub a{
  font-weight: bold;
  font-size: 18px;
}

.nav-bar-items ul li{
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.nav-bar-items ul li a{
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: 0.5s;
  border: 1px solid #fe00ff;
    margin-right: 3px;
    /* border-radius: 5px; */
}

.nav-bar-items ul li a:hover,.nav-bar-items ul li a:focus{
  opacity: 1;
  background-color: #fe00ff !important;
  /* border-radius: 4px; */
}

.antronut{
  text-align: center;
}

.signup-image a{
  background: transparent !important;
}

.signup-image img{
  max-width: 70%;
  margin: auto;
}

/* pattern styles */

.hero-main-content{
  min-height: 70vh;
  width: 100%;
  position: relative;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 10px;
}

.hero-main-content{
  background-image: url(/static/media/wallpaperbg-bottom.45646e44.png),url(/static/media/wallpaperbg.861ddd7f.jpg);
  background-size: 100%,cover;
  background-repeat: no-repeat;
  background-position:bottom,center;
  padding-bottom: 70px;
}

.main-image-container{
  background-image: url(/static/media/wallpaperbg-bottom.45646e44.png),url(/static/media/token-bg.517330ce.jpg);
  background-size: 100%,cover;
  background-repeat: no-repeat;
  background-position:bottom,center;
}

.main-images h2{
  font-weight: bold;
}

.main-images h3{
  font-size: 18px;
  color: #fff;
}

.button-set{
  display: table;
    margin: 20px auto;
    padding: 15px;
}

.button-set a{
  display: inline-table;
  vertical-align: top;
  margin: 0 5px;
  padding: 10px 25px;
  border: 3px solid #9450eb;
  color: #9450eb;
  font-weight: bold;
}

.main-images input{
  margin-right: 10px;
}

.main-images p{
  font-size: 12x;
  color: rgb(167, 167, 167);
  margin-bottom: 30px;
}

.main-images{
  /* background-image: url("./assets/v2images/globe.png");
  background-size: 100%;
  background-position:right;
  background-repeat: no-repeat; */
  min-height: 340px;
  padding-top: 50px;
  /* background-attachment: fixed; */
}


.main-image-container img{
  /* max-width: 80%; */
  margin: auto;
}

.gabung-container{
  padding-bottom: 50px;
}

.hero-sub{
  text-align: center;
  padding-top: 20px;
  display: -webkit-flex;
  display: flex;
}

.hero-items-sub{
  padding: 15px;
    background: #00000059;
    border-radius: 15px;
}

.hero-header-text{
  padding-top: 70px;
  text-align: center !important;
}

.hero-header-text h1{
  text-align: center !important;
}

.gabung-container{
  text-align: right;
  /* margin-top: -200px; */
  position: relative;
  z-index: 100;
  padding-top: 60px;
}

.hero-sub h4, h2{
margin-top: 0;
color: #fff;
text-align: left;
}
.hero-sub h4{
  font-size: 14px;
}

.hero-sub h2{
  font-weight: bold;
  margin-bottom: 25px;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 24px !important;
  text-align: center;
}

.hero-main-content h1{
  font-size: 48px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
    text-align: left;
    margin-bottom: 30px;
}

.logo-container .home-icon-logo a{
  font-size: 16px !important;
  margin-bottom: 0 !important;
  margin-left: 10px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  height: 50px;
  padding: 0 10px;
  background: #313131;
    border-radius: 5px;
    font-size: 12px !important;
    color: magenta;
    text-decoration: none !important;
}

.logo-container .home-icon-logo a:hover{
  background: #222222;
}

.home-icon-logo img{
  max-width: 20px;
  height: auto;
  margin-right: 5px;
}

.home-icon-logo , .logo-container a{
  display: inline-block !important;
  vertical-align: top;
  margin-bottom: 0 !important;
} 

.background {
  background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
          animation: Gradient 15s ease infinite;
  position: absolute;
  min-height: 70vh;
  width: 100%;
  overflow: hidden;
  padding:0;
  margin:0px;
}
.cube {
  position: absolute;
  top: 50vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #D7D4E4;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 12s ease-in forwards infinite;
          animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #FFF ;
}
.cube:nth-child(2) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  left: 65vw;
  top: 50vh;
}
.cube:nth-child(4) {
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
  left: 10vw;
  top: 45vh;
}
.cube:nth-child(6) {
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

/* Animate Background*/
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes cube {
  from {
    -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(20) rotate(960deg) translate(-50%, -50%);
            transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes cube {
  from {
    -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(20) rotate(960deg) translate(-50%, -50%);
            transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

/* animate gabanug */

#animated-example {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

.tokenomics{
  padding-bottom: 20px;
}

#animated-example img{
  width: 100% !important;
}

@keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

/* end hero styles */

.center {
  margin: 0 auto;
}

.outer-ring {
  position: absolute;
  height: 300px;
  width: 300px;
  background-image: linear-gradient(to bottom, #f8d161, #000000);
  border-radius: 50%;
  
  /*  Rotate  */
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.inner-ring {
  position: absolute;
  height: 290px;
  width: 290px;
  background-color: #292626;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 5px;
  display: -webkit-flex;
  display: flex;
  margin-left: 5px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@-webkit-keyframes rotate {
    0% {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}

@keyframes rotate {
    0% {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}

.ring-container{
  width: 300px;
  height: 300px;
  position: relative;
}

/* end ring styles */

.ring-container,.button-container{
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.button-container{
  padding-left: 60px;
}


 .button-container h2, .token-container.qr-image h4{
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b3b3b3;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
}

.token-container.qr-image h4{
  text-align: center !important;
}

.charity-mail a{
  background: none !important;
    color: #b3b3b3 !important;
}

.button-container h1{
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color:#cfae42;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
  margin-top: 0;
}

.token-logo.doc-logo img{
  max-width: 100%;
}

.mission-text{
  letter-spacing: 23px;
    font-size: 70px;
}

.buy-now-class{
  padding-top: 10px !important;
}

.button-container h2{
  margin-top: 15px;
}

.buy-instructions h3, .logo-container a, .mobile-logo-container a{
  color: #f8d161;
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

/* .logo-container a{
  background: #191919;
    padding: 5px 10px;
    border-radius: 5px;
}

.logo-container a:hover{
  text-decoration: none;
  color: #e4b322;
} */

.doc-list-ul, .doc-list-sub-ul{
  padding: 0 !important;
}

.doc-list-ul li{
  list-style: none !important;
}

.white-topic{
  font-weight: bold !important;
  font-size: 16px !important;
}

.white-cont{
  color: #f8d161 !important;
}

.doc-list-sub-ul{
  margin-bottom: 25px !important;
}

.buy-instructions ul li{
  color: #fff;
  list-style: decimal;
  margin-bottom: 10px;
  font-size: 15px;
}

.buy-instructions ul li a, .buy-instructions a{
  color: #f8d161;
}

.buy-instructions p{
  color: #f8d161;
  font-weight: bold;
  margin-left: 15px;
  margin-top: 20px;
}

.token-container.qr-image h3{
  font-size: 46px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f8d161;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    /* margin-bottom: 70px; */
}

.token-container.qr-image{
  padding-bottom: 15px;
}

.button-container.qr-image h3{
  color: #f8d161;
  text-align: center;
}

.button-container.qr-image p{
  color: #b3b3b3;
  text-align: center;
}

.qr-table{
  /* max-width: 600px; */
  margin: auto;
}

.qr-wrapper{
  padding: 20px 40px 70px;
    background: #171717;
    border-radius: 10px;
}

.qr-table th, .qr-table td{
  border: none !important;
  min-width: 200px;
}

.qr-table th{
  background: #000;
  color: #fff;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #403f3f;
}

.qr-table tr{
  background: #1b1b1b;
}

.qr-table tr p{
  margin: 0 !important;
  font-weight: normal;
}

.marketing-ul-container ul{
  padding: 20px !important;
}

.marketing-ul-container ul li{
  font-weight: bold;
}

.buy-instructions.qr-table h3{ 
  color: #bdbdbd;
}

.button-container.qr-image h2{
  color: #f8d161;
  margin-bottom: 0;
}

.button-container.qr-image{
  padding: 0;
}

.button-img-container{
  text-align: center;
  display: table;
  margin: auto;
  padding: 10px 20px;
    background: #ffffff38;
    margin-bottom: 12px;
    border-radius: 5px;
}

.marketing-qr-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 0 !important;
  padding-top: 20px;
}

.button-container.qr-image h2{
  font-size: 13px;
}

.copy-buttons.qr-copy{
  text-align: center;
}

.copy-buttons.qr-copy span{
  position: relative;
    color: #fff !important;
    display: block;
    margin-left: 0 !important;
    margin-top: 0 !important;
    min-height: 20px;
}

.button-img-container img{
  max-width: 300px;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container a{
  min-width: 290px;
  border-radius: 41px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #d5c070, #cca937 100%);
  padding: 15px;
  display: inline-block;
  text-align: center;
  margin: 7px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: #292626;
  text-decoration: none;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all .2s; 
}

.button-container a:hover{
  background-image: linear-gradient(to bottom, #e4b322, #d48910 100%);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.qr-text a{
  background: #f4cd5f;
    padding: 5px 15px;
    color: #000;
    border-radius: 100px;
    font-weight: 600;
}

.qr-text a:hover{
  background: #deb236;
}

.main-logo-cont{
  text-align: center;
  padding-bottom: 35px;
  border-bottom: 1px solid #979797;
  margin-bottom: 35px;
}

.qr-box h2{
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b3b3b3;
  margin: 0;
}

.comming-soon{
  padding: 30px 0;
  min-height: calc(100vh - 170px);
}

.instruction-div h4{
  color: #f8d161;
}

.instruction-div li{
  color: #fff;
  margin-bottom: 10px;
  padding-left: 0;
  list-style-type:decimal;
  font-size: 12px;
}

.instruction-div ul{
  padding-left: 20px;
}

.video-card{
  max-width: 320px;
  display: inline-block;
  margin: 15px;
  vertical-align: top;
}

.video-card a h3{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: left;
    margin-top: 0;
}

.main-video-container{
  text-align: center;
  padding-top: 30px;
}

.main-video-container h1{
  font-family: 'Raleway', sans-serif;
    font-size: 50px;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f8d161;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 0;
}

.comming-soon h1{
  font-family: 'Raleway', sans-serif;
    font-size: 42px;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f8d161;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 0;
}

.note-div{
  padding-top: 20px;
}



.note-div p{
  color: #fff;
  font-size: 12px;
}

.team-main{
  min-height: calc(100vh - 170px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: space-between; */
  padding: 20px 0 40px;
}

.team-sub{
  display: block;
    text-align: center;
    /* max-width: 900px; */
    margin: auto;
}



.team-item h4{
  color: #f8d161;
}

.team-item h3{
  color: #f8d161;
  font-weight: bold;
  margin-top: 0;
}

.team-item{
  padding: 12px 12px;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  min-width: 320px;
  max-width: 320px;
  display: inline-block;
  /* min-height: 466px; */
  vertical-align: top;
  background: #000;
}

.token-container{
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 1px solid #979797;
  padding-bottom: 35px;
}

.token-container h3, .token-container p, .token-container a{
  color: #f8d161;
}

.token-container a{
  font-weight: 700;
  font-size: 16px;
}

.team-item a{
  color: #0099b0;
}

.qr-text, .qr-box img{
  display: inline-block;
  vertical-align: top;
}

.qr-text{
  margin-left: 20px;
  padding-bottom: 15px;
}

.qr-box p{
  color: #b3b3b3;
  margin-top: 5px;
  word-wrap: break-word;
  font-size: 12px;
}

.qr-box{
  margin-top: 20px;
}

.holders h4{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.14px;
  color: rgba(255, 255, 255, 0.8);
}

.holders h3{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.14px;
  color: #ffffff;

}

.holders{
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-top: 20px;
}

.divider img{
  width: 101%;
  /* max-height: 90px; */
  margin-left: -1%;
}

.divider {
  padding: 15px 0;
}

.image-container{
  margin-bottom: 15px;
}

.main-image-container{
  /* background-image: url('/src/assets/hero-animation/shapes.png'); */
}

.main-images{
  padding-top: 40px;
  padding-bottom: 40px;
}
.image-container h3{
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.6);
}

.image-container p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #ffffffcf;
}



.main-images .image-container{
  text-align: left;
}

.main-road-map{
  padding-top: 60px;
  padding-bottom: 30px;
  background-image: url(/static/media/wallpaperbg-up.ff5caaa3.png),url(/static/media/wallpaperbg-bottom.45646e44.png),url(/static/media/road-map-bg.dd66b7c4.jpg);
  background-size: 100%,100%,cover;
  background-repeat: no-repeat;
  background-position:top,bottom,center;

}

.main-road-map h2{
  font-size: 70px;
  font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fe00ff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
  margin-bottom: 70px;
}

.css-vcfpfv{
  background-image: linear-gradient(to bottom, #ff00ff, #9b009b 99%);
  outline: 10px solid #9b009b !important;
}

.css-1b3lipl:before{
  background-color: #ff00ff !important;
}

.road-ul li{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #ffffff;
  list-style: none;
  padding-left: 10px;
  margin-bottom: 15px;
}

.road-ul li a{
  color: #ededed;
  text-decoration: none;
}

.road-ul li::before{
  content: "-";
  position: absolute;
  margin-left: -10px;
}
.road-ul .sub-ul li::before{
  display: none;
}

.road-ul .sub-ul li{
  padding-left: 25px;
  margin-bottom: 8px;
  color: #ddd;
}

.road-ul .sub-ul{
  padding-bottom: 15px;
}

.css-692fgp{
  background-color: #2f2d2d !important;
  font-size: 24px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.4) !important;
}

.copyrights{
  background: #000000;
  padding: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

}

.mobile-nav{
  display: none;
}

.qr-box img{
  width: 92px;
}

.contact-items{
  padding: 50px 0 40px;  
}

.contact-header{
  font-family: 'Raleway', sans-serif;
    font-size: 54px;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fe00ff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    /* text-align: center; */
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}

.contact-items textarea{
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 20px;
  background-color: #ffffff1f;
}

.hero-header-text{
  text-align: center;

}

.hero-header-text img{
  margin: auto;
  margin-bottom: 20px;
}

.contact-header{
  padding-top: 20px;
}

.contact-emails a{
  color: #fe00ff !important;
}

.hero-header-text a{
  display: table;
  margin: auto;
  font-size: 30px;
  padding: 15px 30px;
  border: 2px solid #000;
  background: #874ce2;
  -webkit-text-fill-color: #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    color: #000 !important;
    font-weight: bold;
    border-radius: 10px;
    text-decoration: none !important;
}

.hero-header-text a:hover{
  text-decoration: none;
}

.form-control{
  background-color: #ffffff1f;
}

.contact-items button{
  width: 100%;
}

.contact-items a{
  display: block;
  font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #ffffff;
    list-style: none;
    padding-left: 10px;
    margin-bottom: 15px;
}

.buy-container{
  padding-top: 30px;
  padding-bottom: 30px;
}

.main-video{
  max-width: 1020px;
    margin: 15px auto;
}

.inner-ring img{
  max-width: 200px;
    height: auto;
}

.scrolltop-div{
  display: table;
    background: #ffffff3d;
    height: 60px;
    width: 60px;
    position: fixed;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    right: 30px;
    bottom: 30px;
    transition: 0.5s;
    z-index: 1000;
    cursor: pointer;
}

.scrolltop-div .fas{
  color: #ffffff7d;
    font-size: 40px;
}

.team-item img{
  max-height: 290px;
    margin-bottom: 20px;
}

.vidyard-player-container{
  margin-left: -25% !important;
}

.lottieAvatar{
  /* max-width: 255px; */
  overflow:hidden;
}

.copy-buttons span{
  position: absolute;
  margin-left: -55px;
  color: #fff !important;
  margin-top: 10px;
}

.button-logo img{
  max-width: 700px;
  width: 100%;
  margin-bottom: 15px;
}

.mission-text-container img{
  max-height: 42px;
    margin-top: -5px;
}

.copy-buttons h4{
  font-weight: 700;
    font-size: 16px;
    color: #f8d161;
}

.copy-buttons button{
  padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    color: #000;
    background: #e9bb4f;
    border: none;
    font-weight: bold;
}

.copy-buttons a{
  color: #000;
  font-weight: bold;
  font-size: 14px !important;
}

.button-container.how-to-buy{
  padding-left: 0;
}

.token-logo{
  text-align: center;
  padding-top: 15px;
}

.token-logo img{
  max-width: 250px;
  margin: 15px auto;
}

.faq-row-wrapper{
  background: transparent !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw svg {
  fill: white !important;
  fill: var(--arrow-color, white) !important;
}

.faq-items h4{
  line-height: 25px;
  margin-bottom: 15px;
}

/* .main-logo-cont.marketing-qr-container.charity{
  justify-content: center;
} */

.main-logo-cont.marketing-qr-container.charity .qr-wrapper {
  padding: 20px 40px;
 margin-bottom: 35px;
}

.main-doc-box-container{
  /* background: url("assets/ast/ast-02.png"); */
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  min-height: 500px;
}

.main-doc-box-container a{
  max-width: 400px;
  width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.5);
    font-size: 30px;
    padding: 15px 30px;
    background: #fe00ff;
    color: #fff;
    text-align: center;
    margin: 7px 7px 7px auto;
    text-decoration: none;
    margin-top: 35px;
    margin-bottom: 25px;
    transition: all .2s;
    display: table;
    font-weight: bold;
}

.main-doc-box-container .received{
  background-image: linear-gradient(to bottom, #70d57c, #0b6f41 100%) !important;
  color: #000 !important;
}
.market-box-sub img{
  max-width: 128px;
}
.main-doc-box-container a:hover{
  background: #c30ac3;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.main-video-image img{
  text-align: center;
  margin: 15px auto;
}

.main-images a{
  background: #c30ac3;
  border-color: #c30ac3;
  text-decoration: none !important;
  padding: 5px 20px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;

}

.main-doc-box-container h3{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  margin: 0;
}

/* .social-container{
  display: none;
} */

.migration-div h3{
  color: #979797;
    text-align: right;
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.help-buttons{
  padding-top: 20px;
}

.help-buttons a{
  display: table;
    width: auto;
    min-width: 135px;
    margin: 10px 20px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    background: transparent;
    color: #fff;
}

.migration-div.mobile{
  display: none;
}

.home-icon a{
  display: table;
    width: auto;
    min-width: 135px;
    margin: 10px 5px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    background: transparent;
    color: #fff;
}

.social-cell img{
  max-width: 13px;
  display: table;
  margin: auto;
}

.instruction-div h3{
color: #fff;
font-weight: normal;
}

@media (min-width:769px) and (max-width:1200px){
  .button-logo img{
    max-width: 500px;
    width: 100%;
    margin-top: 30px;
  }
}



@media (max-width:768px){

  .market-box{
    width: 80%;
  }

  .mobile-logo-container img{
    max-width: 60px;
  }

  .home-icon a{
    margin: 12px auto;
  }

  .migration-div h3{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
  }

  .comming-soon{
    padding: 0;
  }

  .migration-div.desktop{
    display: none;
  }

  .migration-div.mobile{
    display: block;
  }

  .hero-header-text img{
    width: 90%;
  }
  .social-links{
    background-color: #000000bf;
  }

.el-shape{
  display: none;
  
}

.button-set a{
  width: 100%;
  margin-bottom: 10px;
}

.contact-emails a, .contact-emails h3{
  font-size: 16px;
  text-align: center;
}

  /* .navbar-header{
    text-align: center !important;
  } */

  /* .navbar-toggle{
    display: none !important;
  } */

  .social-container{
    display: block;
  }

  .main-doc-box-container{
    background-size: 70%;
    background-position: right bottom;
    padding-top: 15px;
  }

  #roadmap .container{
    background: none;
  }

  .token-logo.doc-logo img {
    max-width: 300px;
}

  .button-container.qr-image{
    margin-bottom: 35px;
  }

  .button-img-container img{
    max-width: 200px;
  }

  .qr-wrapper{
    padding: 5px 15px !important;
  }

  .marketing-qr-container{
    display: block;
  }

  .faq-items .row-title, .faq-items .faq-row{
    display: block !important;
  }

  .faq-items .faq-row{
    max-width: 330px !important;
  }

  .faq-items h4,  .faq-items a,  .row-title-text{
    line-height: 15px !important;
    font-size: 12px !important;
    word-wrap: break-word;
  }

  .copy-buttons h4 {
    font-size: 12px;
}

  .button-logo img {
    margin-top: 30px;
    max-width: 290px;
    margin-bottom: -15px;
}

.mission-text-container img {
  max-height: 24px;
  margin-top: -2px;
}

  .token-container.qr-image h3{
    font-size: 24px;
  }

  .button-container.qr-image h3{
    font-size: 18px;
  }
  .button-container.qr-image h2{
    font-size: 12px;
  }

  .qr-table th, .qr-table td {
    border: none !important;
    min-width: 100px;
}

  .ring-container .lottie-sub{
    min-height: inherit !important;
    position: relative !important;
  }


  .lottie-sub{
    height: 100% !important;
    position: absolute;
  }

  .lottie-main {
    max-width: 100% !important;
    margin: 0 auto;
}

  /* .team-main{
    display: block;
  }

  .team-item {
    max-width: 250px;
    margin: 5px auto;
} */

  .buy-container .button-container a{
    width: 100%;
    min-width: 100%;
    font-size: 12px;
  }
  .token-container a ,.token-container p{
    font-size: 12px;
}

.token-container h3{
  font-size: 18px;
}

.buy-container .main-logo-cont{
  padding-bottom: 20px;
}

.buy-instructions h3{
  font-size: 18px;
}

.buy-instructions ul li {
  font-size: 12px;
}

.buy-instructions ul{
  padding: 0 0 0 15px;
}

  .button-container h1{
    margin-top: 15px;
  }

  .mission-text {
    letter-spacing: 2px;
    font-size: 66px;
    margin-top: 15px;
}
  .qr-box p{
    /* max-width: 230px; */
  }
    .mobile-nav{
      display: block;
    }
    .nav-bar-items{
      display: none;
    }
    .nav-container{
      display: block;
    }

    .road-ul li{
      word-wrap: break-word;
      font-size: 14px;
    }

    .navbar-inverse{
      background: transparent;
      border: none;
      margin: 0;
    }
  .logo-container{
    display: none;
    width: 20%;
    vertical-align: top;
  }

  .qr-text{
    margin: 0;
    width: 100%;
  }

  #myNavbar{
    position: absolute;
    z-index: 100;
    background: #313030;
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
  }

  .mobile-nav{
    display: block;
    width: 100%;
    vertical-align: top;
  }

  .navbar-brand img{
    width: 78px;
  }

  .navbar-toggle{
    margin-right: 0;
  }

  .navbar-brand{
    padding: 0;
  }

  .social-cell i{
    font-size: 12px;
  }
  .social-icon{
    width: 25px;
    height: 25px;
    margin: 3px;
  }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    background-color: #828282;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 35px;
    opacity: 0.9;
  }

  .image-container {
    width: 100%;
    display: block;
    vertical-align: top;
    padding: 25px;
    margin-bottom: 30px;
  }
  .button-container {
    padding-left: 0;
    text-align: center;
  }
  .button-container h1, .button-container h2{
    font-size: 24px;
    text-align: center;
  }
  .qr-box h2{
    font-size: 16px;
    margin-top: 22px;
    
  }

  .qr-box{
    text-align: center;
  }
  .holders {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    background: #ffffff24;
    padding: 5px;
    border-radius: 5px;
}
html, body {
  overflow-x: hidden;
  width: 100%;
}
.social-icon img{
  width: 12px;
  height: auto;
}

.social-links{
  padding-left: 0;
  padding-right: 0;
}

.social-links .container{
  padding-left: 5px;
  padding-right: 5px;
}

.main-road-map h2{
  font-size: 52px;
  margin-bottom: 54px;
}

.navbar-header {
  float: none;
}
.navbar-left,.navbar-right {
  float: none !important;
}
.navbar-toggle {
  display: block;
}
.navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-collapse.collapse {
  display: none!important;
}
.navbar-nav {
  float: none!important;
  margin-top: 7.5px;
}
.navbar-nav>li {
  float: none;
}
.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.collapse.in{
  display:block !important;
}


}
